export class LstEstimateEnumCol {

	public static readonly NO = new LstEstimateEnumCol("no", "Number");
	public static readonly DATE = new LstEstimateEnumCol("date", "Date");
	public static readonly CUSTOMER = new LstEstimateEnumCol("customer", "Customer Name");
	public static readonly CITY = new LstEstimateEnumCol("city", "City");
	public static readonly STATE = new LstEstimateEnumCol("state", "State Name");
	public static readonly QTY = new LstEstimateEnumCol("qty", "Total Quantity");
	public static readonly NOTE = new LstEstimateEnumCol("note", "Note");
	public static readonly SALES_ORDER_NO = new LstEstimateEnumCol("sales_order_no", "Sales Order No");
	public static readonly STATUS = new LstEstimateEnumCol("status", "Status");
	public static readonly LOCK_STOCK_FOR_DAYS = new LstEstimateEnumCol("lock_stock_for_days", "Lock Stock For Days");
	public static readonly CREATED_BY = new LstEstimateEnumCol("created_by", "Created By");
	public static readonly CREATED_AT = new LstEstimateEnumCol("created_at", "Created At");

	private constructor(public readonly key: string, public readonly name: string) {
		
	}

	static get values(): LstEstimateEnumCol[] {
		return [
			this.NO,
			this.DATE,
			this.CUSTOMER,
			this.CITY,
			this.STATE,
			this.QTY,
			this.NOTE,
			this.SALES_ORDER_NO,
			this.STATUS,
			this.LOCK_STOCK_FOR_DAYS,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstEstimateEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
