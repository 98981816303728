import { YesNoEnum } from "../enums/YesNoEnum";
import DropDownItemType from "../types/DropDownItemType";

export default class YesNoHelper {

	static getYesNosForDd() {
		let finalArr: DropDownItemType[] = [];
		YesNoEnum.values.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;
	}

	static getYesNoForDd(yesNo: YesNoEnum): DropDownItemType {
		return {
			id: yesNo.id,
			name: yesNo.name
		};
	}

}