import { VoucherTypeByDrCrEnum } from "./VoucherTypeByDrCrEnum";

export class VoucherTypeEnum {

	public static readonly PURCHASE = new VoucherTypeEnum(1, "", "Purchase", VoucherTypeByDrCrEnum.DR);
	public static readonly SALES = new VoucherTypeEnum(2, "SALES", "Sales", VoucherTypeByDrCrEnum.CR);
	public static readonly PURCHASE_RETURN = new VoucherTypeEnum(3, "", "Purchase Return", VoucherTypeByDrCrEnum.CR);
	public static readonly SALES_RETURN = new VoucherTypeEnum(4, "", "Sales Return", VoucherTypeByDrCrEnum.DR);
	public static readonly PAYMENT = new VoucherTypeEnum(5, "", "Payment", VoucherTypeByDrCrEnum.CR);
	public static readonly RECEIPT = new VoucherTypeEnum(6, "", "Receipt", VoucherTypeByDrCrEnum.DR);
	public static readonly CONTRA = new VoucherTypeEnum(15, "", "Contra", null);
	public static readonly JOURNAL = new VoucherTypeEnum(16, "", "Journal", VoucherTypeByDrCrEnum.DR_CR);
	public static readonly COMM_VOU = new VoucherTypeEnum(22, "", "Commission Voucher", null);
	public static readonly COMM_VOU_PAYABLE = new VoucherTypeEnum(23, "", "Commission Voucher Payable", VoucherTypeByDrCrEnum.DR);
	public static readonly CASH_DISC_VOU = new VoucherTypeEnum(24, "", "Cash Discount Voucher", null);
	public static readonly SALES_ORDER = new VoucherTypeEnum(26, "", "Sales Order", null);
	public static readonly PURCHASE_ORDER = new VoucherTypeEnum(27, "", "Purchase Order", null);
	public static readonly PI = new VoucherTypeEnum(28, "", "Proforma Invoice", null);
	public static readonly INV_ADJUST = new VoucherTypeEnum(29, "", "Inventory Adjust", null);
	public static readonly JOURNAL_ITEM = new VoucherTypeEnum(30, "", "Journal", VoucherTypeByDrCrEnum.DR_CR);
	public static readonly OPENING_BALANCE = new VoucherTypeEnum(31, "", "Opening Balance", VoucherTypeByDrCrEnum.DR_CR);
	public static readonly CDP_JOURNAL_VOU = new VoucherTypeEnum(32, "", "Cash Discount / Penalty", VoucherTypeByDrCrEnum.DR_CR);
	public static readonly ORDER_ADJUST = new VoucherTypeEnum(33, "", "Order Adjust", VoucherTypeByDrCrEnum.DR_CR);
	public static readonly ESTIMATE = new VoucherTypeEnum(34, "", "Estimate", null);

	private constructor(
		public readonly id: number,
		public readonly enumName: string,
		public readonly name: string,
		public readonly voucherTypeByDrCrEnum: VoucherTypeByDrCrEnum | null) {
	}


	static get values(): VoucherTypeEnum[] {
		return [
			this.PURCHASE,
			this.SALES,
			this.PURCHASE_RETURN,
			this.SALES_RETURN,
			this.PAYMENT,
			this.RECEIPT,
			this.CONTRA,
			this.JOURNAL,
			this.COMM_VOU,
			this.COMM_VOU_PAYABLE,
			this.CASH_DISC_VOU,
			this.SALES_ORDER,
			this.PURCHASE_ORDER,
			this.PI,
			this.INV_ADJUST,
			this.JOURNAL_ITEM,
			this.OPENING_BALANCE,
			this.CDP_JOURNAL_VOU,
			this.ORDER_ADJUST,
			this.ESTIMATE,
		];
	}

	static get(voucherTypeByDrCrEnum: VoucherTypeByDrCrEnum): VoucherTypeEnum[] {
		let res: VoucherTypeEnum[] = [];
		this.values.forEach(element => {
			if (element.voucherTypeByDrCrEnum !== null
				&& element.voucherTypeByDrCrEnum === voucherTypeByDrCrEnum) {
				res.push(element);
			}
		});
		return res;
	}

	static getIds(voucherTypeByDrCrEnum: VoucherTypeByDrCrEnum): number[] | null {
		let res: number[] = [];
		this.values.forEach(element => {
			if (element.voucherTypeByDrCrEnum != null
				&& element.voucherTypeByDrCrEnum === voucherTypeByDrCrEnum) {
				res.push(element.id);
			}
		});
		return res;
	}

	static getById(id: number): VoucherTypeEnum | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.id === id) {
				return element;
			}
		}
		return null;
	}

}
