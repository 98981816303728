
export class GetVouOthChObjectTypeEnum {

	public static readonly VOU_OTH_CH = new GetVouOthChObjectTypeEnum(1, "VOU_OTH_CH", "");
	public static readonly VOU_OTH_CH_FOR_ESTIMATE = new GetVouOthChObjectTypeEnum(2, "VOU_OTH_CH_FOR_ESTIMATE", "");

	private constructor(
		public readonly id: number,
		public readonly enumName: string,
		public readonly name: string) {
	}

	static get values(): GetVouOthChObjectTypeEnum[] {
		return [
			this.VOU_OTH_CH,
			this.VOU_OTH_CH_FOR_ESTIMATE,
		];
	}

	static getById(id: number): GetVouOthChObjectTypeEnum | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.id === id) {
				return element;
			}
		}
		return null;
	}

}
