interface ListViewButtonBarProps {
  colNames: string[];
}

function ListViewPagination({ colNames }: ListViewButtonBarProps) {
  return (
    <div className="d-flex justify-content-between">
      <div className="ms-2">
        <div className="input-group">
          <span className="input-group-text" id="basic-addon1">
            <i className="fa-solid fa-magnifying-glass"></i>
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="Find"
            aria-label="Find"
            aria-describedby="basic-addon1"
          />
        </div>
      </div>

      <div className="d-flex">
        <div className="me-2">
          <select className="form-select" aria-label=".form-select-sm example">
            <option value="1">Rows 100</option>
            <option value="2">Rows 200</option>
            <option value="3">Rows 500</option>
          </select>
        </div>

        <ul className="pagination me-2">
          <li className="page-item">
            <a className="page-link" href="#" aria-label="Previous">
              <i className="fa-solid fa-angles-left"></i>
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#">
              <i className="fa-solid fa-angle-left"></i>
            </a>
          </li>
        </ul>

        <div className="me-2">
          <select className="form-select" aria-label=".form-select-sm example">
            <option value="1">Page 1</option>
            <option value="2">Page 2</option>
            <option value="3">Page 30</option>
          </select>
        </div>

        <ul className="pagination me-2">
          <li className="page-item">
            <a className="page-link" href="#" aria-label="Next">
              <i className="fa-solid fa-angle-right"></i>
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#" aria-label="Next">
              <i className="fa-solid fa-angles-right"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ListViewPagination;
