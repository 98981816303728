export default class ListLeadSm {

	name: string | undefined;
	city: string | undefined;
	cities: string[] = [];
	stateId: number | undefined;
	state: string | undefined;
	contactNo: string | undefined;
	leadSrcId: number | undefined;
	leadStatusId: number | undefined;
	assignedToUserId: number | undefined;

	reset(): void {
		this.name = undefined;
		this.city = undefined;
		this.cities = [];
		this.stateId = undefined;
		this.state = undefined;
		this.contactNo = undefined;
		this.leadSrcId = undefined;
		this.leadStatusId = undefined;
		this.assignedToUserId = undefined;
	}

	isBlank(): boolean {
		return this.city === undefined
			&& this.cities.length === 0
			&& this.stateId === undefined
			&& this.state === undefined
			&& this.contactNo === undefined
			&& this.leadSrcId === undefined
			&& this.leadStatusId === undefined
			&& this.assignedToUserId === undefined;
	}
}
