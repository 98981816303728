export default class ListCustomerSm {

	name: string | undefined;
	city: string | undefined;
	stateId: number | undefined;
	state: string | undefined;
	contactNo: string | undefined;

	reset(): void {

		this.name = undefined;
		this.city = undefined;
		this.stateId = undefined;
		this.state = undefined;
		this.contactNo = undefined;
	}

	isBlank(): boolean {
		return this.name === undefined
			&& this.city === undefined
			&& this.stateId === undefined
			&& this.state === undefined
			&& this.contactNo === undefined;
	}
}
