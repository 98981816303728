export default class ListLeadSrcSm {

	name: string | undefined;

	reset(): void {
		this.name = undefined;
	}

	isBlank(): boolean {
		return this.name === undefined;
	}
}
