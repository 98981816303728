export default class ListPaymentSm {

	no: number | undefined;
	fromAcctId: number | undefined;
	fromAcctName: string | undefined;
	toAcctId: number | undefined;
	toAcctName: string | undefined;
	dateFrom: Date | undefined;
	dateTo: Date | undefined;

	reset(): void {

		this.no = undefined;
		this.fromAcctId = undefined;
		this.fromAcctName = undefined;
		this.toAcctId = undefined;
		this.toAcctName = undefined;
		this.dateFrom = undefined;
		this.dateTo = undefined;
	}

	isBlank(): boolean {
		return this.no === undefined
			&& this.fromAcctId === undefined
			&& this.fromAcctName === undefined
			&& this.toAcctId === undefined
			&& this.toAcctName === undefined
			&& this.dateFrom === undefined
			&& this.dateTo === undefined;
	}
}
