export default class ListOrderAdjustSm {

	dateFrom: Date | undefined;
	dateTo: Date | undefined;
	acctId: number | undefined;
	orderTypeId: number | undefined;
	no: number | undefined;

	reset(): void {
		this.dateFrom = undefined;
		this.dateTo = undefined;
		this.acctId = undefined;
		this.orderTypeId = undefined;
		this.no = undefined;
	}

	isBlank(): boolean {
		return this.dateFrom === undefined
			&& this.dateTo === undefined
			&& this.acctId === undefined
			&& this.orderTypeId === undefined
			&& this.no === undefined;
	}
}
