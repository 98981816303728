export default class ListReminderSm {

	dateFrom: Date | undefined;
	dateTo: Date | undefined;
	parentTypeId: number | undefined;
	leadId: number | undefined;
	acctId: number | undefined;
	subject: string | undefined;
	isOn: boolean | undefined;
	createdByUserId: number | undefined;
	assignedToUserId: number | undefined;

	reset(): void {
		this.dateFrom = undefined;
		this.dateTo = undefined;
		this.parentTypeId = undefined;
		this.leadId = undefined;
		this.acctId = undefined;
		this.subject = undefined;
		this.isOn = undefined;
		this.createdByUserId = undefined;
		this.assignedToUserId = undefined;
	}

	isBlank(): boolean {
		return this.dateFrom === undefined
			&& this.dateTo === undefined
			&& this.parentTypeId === undefined
			&& this.leadId === undefined
			&& this.acctId === undefined
			&& this.subject === undefined
			&& this.isOn === undefined
			&& this.createdByUserId === undefined
			&& this.assignedToUserId === undefined;
	}
}
