
export default class StringHelper2 {

	static isInt(str: string) {
		return !isNaN(parseInt(str)) && isFinite(parseInt(str));
	}

	static isNum(str: string) {
		return !isNaN(parseFloat(str));
	}


}