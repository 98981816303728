export default class ListJournalSm {

	no: number | undefined;
	acctId: number | undefined;
	acctName: string | undefined;
	dateFrom: Date | undefined;
	dateTo: Date | undefined;

	reset(): void {

		this.no = undefined;
		this.acctId = undefined;
		this.acctName = undefined;
		this.dateFrom = undefined;
		this.dateTo = undefined;
	}

	isBlank(): boolean {
		return this.no === undefined
			&& this.acctId === undefined
			&& this.acctName === undefined
			&& this.dateFrom === undefined
			&& this.dateTo === undefined;
	}
}
