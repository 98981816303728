export default class ListInvAdjustSm {

	no: number | undefined;
	dateFrom: Date | undefined;
	dateTo: Date | undefined;
	invAdjustTypeId: number | undefined;

	reset(): void {

		this.no = undefined;
		this.dateFrom = undefined;
		this.dateTo = undefined;
		this.invAdjustTypeId = undefined;
	}

	isBlank(): boolean {
		return this.no === undefined
			&& this.dateFrom === undefined
			&& this.dateTo === undefined
			&& this.invAdjustTypeId === undefined;
	}
}
