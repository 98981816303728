export class UnitOperatorTypeEnum {
	public static readonly DIVIDE = new UnitOperatorTypeEnum(1, "Divide", "/");
	public static readonly MULTIPLY = new UnitOperatorTypeEnum(2, "Multiply", "X");

	private constructor(
		public readonly id: number,
		public readonly name: string,
		public readonly symbol: string) {
	}


	static get values(): UnitOperatorTypeEnum[] {
		return [
			this.DIVIDE,
			this.MULTIPLY,
		];
	}

	static getById(id: number): UnitOperatorTypeEnum | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.id === id) {
				return element;
			}
		}
		return null;
	}

	static getByName(name: string): UnitOperatorTypeEnum | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.name === name) {
				return element;
			}
		}
		return null;
	}

	static getBySymbol(symbol: string): UnitOperatorTypeEnum | null {
		this.values.forEach(element => {
			if (element.symbol === symbol) {
				return element;
			}
		});
		return null;
	}

	static getSymbols(): string[] {

		let strings: string[] = [];
		this.values.forEach(element => {
			strings.push(element.symbol);
		});
		return strings;

	}

}
