export default class ListVouOthChargeSm {
	//field 
	name: string | undefined;

	//function 
	reset(): void {
		this.name = undefined;
	}

	isBlank(): boolean {
		return this.name === undefined;
	}

}