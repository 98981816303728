export default class ListCdpJournalVouSm {

	no: number | undefined;
	customerAcctId: number | undefined;
	customerAcctName: string | undefined;
	cdpAcctId: number | undefined;
	cdpAcctName: string | undefined;
	dateFrom: Date | undefined;
	dateTo: Date | undefined;

	reset(): void {

		this.no = undefined;
		this.customerAcctId = undefined;
		this.customerAcctName = undefined;
		this.cdpAcctId = undefined;
		this.cdpAcctName = undefined;
		this.dateFrom = undefined;
		this.dateTo = undefined;

	}

	isBlank(): boolean {
		return this.no === undefined
			&& this.customerAcctId === undefined
			&& this.customerAcctName === undefined
			&& this.cdpAcctId === undefined
			&& this.cdpAcctName === undefined
			&& this.dateFrom === undefined
			&& this.dateTo === undefined;
	}
}
