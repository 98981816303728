import { useState } from "react";
import Container from "./components/Container";
import Login from "./components/Login";
import { checkIsLoggedIn } from "./Util";

function App() {
  const [loggedIn, setLoggedIn] = useState(checkIsLoggedIn());

  if (loggedIn) return <Container />;

  return <Login setLoggedIn={setLoggedIn} />;
}

export default App;
