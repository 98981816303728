export default class ListContraSm {

	no: number | undefined;
	acctFromId: number | undefined;
	acctFromName: string | undefined;
	acctToId: number | undefined;
	acctToName: string | undefined;
	dateFrom: Date | undefined;
	dateTo: Date | undefined;

	reset(): void {

		this.no = undefined;
		this.acctFromId = undefined;
		this.acctFromName = undefined;
		this.acctToId = undefined;
		this.acctToName = undefined;
		this.dateFrom = undefined;
		this.dateTo = undefined;
	}

	isBlank(): boolean {
		return this.no === undefined
			&& this.acctFromId === undefined
			&& this.acctFromName === undefined
			&& this.acctToId === undefined
			&& this.acctToName === undefined
			&& this.dateFrom === undefined
			&& this.dateTo === undefined;
	}
}
