
import RateTypeEnum from "../enums/RateTypeEnum";
import DropDownItemType from "../types/DropDownItemType";

export default class RateTypeHelper {

	static getRateTypesForDd() {
		let finalArr: DropDownItemType[] = [];
		RateTypeEnum.values.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;
	}

	static getRateTypeForDd(rateType: RateTypeEnum): DropDownItemType {
		return {
			id: rateType.id,
			name: rateType.name
		};
	}

}