export default class ListAcctSm {

	name: string | undefined;
	acctTypeId: number | undefined;
	acctType: string | undefined;

	reset(): void {

		this.name = undefined;
		this.acctTypeId = undefined;
		this.acctType = undefined;

	}

	isBlank(): boolean {
		return this.name === undefined
			&& this.acctTypeId === undefined
			&& this.acctType === undefined;
	}
}
