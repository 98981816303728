export default class ListCommncSm {

	parentTypeId: number | undefined;
	leadId: number | undefined;
	acctId: number | undefined;
	isOn: boolean | undefined;
	createdByUserId: number | undefined;
	assignedToUserId: number | undefined;

	reset(): void {

		this.parentTypeId = undefined;
		this.leadId = undefined;
		this.acctId = undefined;
		this.isOn = undefined;
		this.createdByUserId = undefined;
		this.assignedToUserId = undefined;
	}

	isBlank(): boolean {
		return this.parentTypeId === undefined
			&& this.leadId === undefined
			&& this.acctId === undefined
			&& this.isOn === undefined
			&& this.createdByUserId === undefined
			&& this.assignedToUserId === undefined;
	}
}
