
export default class AcctJournalSumHelper {

	public debit: number = 0;
	public credit: number = 0;

	constructor(
		public othDebit: number | null,
		public othCredit: number | null
	) {
		if (othDebit !== null) {
			this.debit = othDebit;
		}
		if (othCredit !== null) {
			this.credit = othCredit;
		}
	}

	public isDrBig(): boolean {
		if (this.debit == null) {
			this.debit = 0;
		}
		if (this.credit == null) {
			this.credit = 0;
		}
		return this.debit > this.credit;
	}

	public isCrBig(): boolean {
		if (this.debit == null) {
			this.debit = 0;
		}
		if (this.credit == null) {
			this.credit = 0;
		}
		return this.credit > this.debit;
	}

	public isCrDrSame(): boolean {
		if (this.debit == null) {
			this.debit = 0;
		}
		if (this.credit == null) {
			this.credit = 0;
		}
		return this.credit === this.debit;
	}

	public getCrBal(): number {
		if (this.debit == null) {
			this.debit = 0;
		}
		if (this.credit == null) {
			this.credit = 0;
		}
		return this.credit - this.debit;
	}

	public getDrBal(): number {
		if (this.debit == null) {
			this.debit = 0;
		}
		if (this.credit == null) {
			this.credit = 0;
		}
		return this.debit - this.credit;
	}

}