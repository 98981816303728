export class ListMenuEnum {
	public static readonly DAY_BOOK = new ListMenuEnum(1);
	public static readonly VOU_OTH_CHARGE = new ListMenuEnum(2);
	public static readonly PROD = new ListMenuEnum(3);
	public static readonly INV_ADJUST = new ListMenuEnum(4);
	public static readonly ACCT_TYPE = new ListMenuEnum(5);
	public static readonly ACCT = new ListMenuEnum(6);
	public static readonly AGENT = new ListMenuEnum(7);
	public static readonly SUPPLIER = new ListMenuEnum(8);
	public static readonly CUSTOMER = new ListMenuEnum(9);
	public static readonly BANK = new ListMenuEnum(10);
	public static readonly PURCHASE_ORDER = new ListMenuEnum(11);
	public static readonly PURCHASE = new ListMenuEnum(12);
	public static readonly PURCHASE_RETURN = new ListMenuEnum(13);
	public static readonly SALES_ORDER = new ListMenuEnum(14);
	public static readonly PI = new ListMenuEnum(15);
	public static readonly SALES = new ListMenuEnum(16);
	public static readonly SALES_RETURN = new ListMenuEnum(17);
	public static readonly PAYMENT = new ListMenuEnum(18);
	public static readonly RECEIPT = new ListMenuEnum(19);
	public static readonly CONTRA = new ListMenuEnum(20);
	public static readonly CASH_DISC_VOU = new ListMenuEnum(21);
	public static readonly COMM_VOU = new ListMenuEnum(22);
	public static readonly CURRENT_STOCK_BY_CAT = new ListMenuEnum(23);
	public static readonly CURRENT_STOCK_VAL = new ListMenuEnum(24);
	public static readonly LOW_STOCK = new ListMenuEnum(25);
	public static readonly JOURNAL = new ListMenuEnum(26);
	public static readonly CDP_JOURNAL_VOU = new ListMenuEnum(27);
	public static readonly SALES_ORDER_PENDING_DET = new ListMenuEnum(28);
	public static readonly SALES_ORDER_PENDING_BY_CUST = new ListMenuEnum(29);
	public static readonly SALES_ORDER_PENDING_BY_PROD = new ListMenuEnum(30);
	public static readonly PURCHASE_ORDER_PENDING_DET = new ListMenuEnum(31);
	public static readonly PURCHASE_ORDER_PENDING_BY_SUPPLIER = new ListMenuEnum(32);
	public static readonly PURCHASE_ORDER_PENDING_BY_PROD = new ListMenuEnum(33);
	public static readonly ORDER_ADJUST = new ListMenuEnum(34);
	public static readonly ESTIMATE = new ListMenuEnum(35);
	public static readonly LEAD = new ListMenuEnum(36);
	public static readonly COMMNC = new ListMenuEnum(37);
	public static readonly REMINDER = new ListMenuEnum(38);
	public static readonly DUE_RCVABLE_BY_SALES = new ListMenuEnum(39);
	public static readonly DUE_RCVABLE_BY_CUSTOMER = new ListMenuEnum(40);
	public static readonly RPT_ESTIMATE_PENDING_PRODUCT = new ListMenuEnum(41);

	private constructor(
		public readonly id: number) {
	}

}
