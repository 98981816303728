import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { BASE_URL, TOKEN } from "../../config";

type UnitReq = {
    name: string,
    code: string,
    isDerived: boolean,
    baseUnitId: number | null,
    operatorId: number | null,
    qty: number | null
}

type UnitRes = {
    id: number;
    code: string;
    name: string;
    isDerived: boolean;
    baseUnitName: string;
    operatorId: number
    num: number;
}


function AddUnit() {

    const [code, setCode] = useState("");
    const [name, setName] = useState("");
    const [isDerived, setIsDerived] = useState(false);
    const [baseUnitId, setBaseUnitId] = useState(-1);
    const [operatorId, setOperatorId] = useState(-1);
    const [qty, setQty] = useState<string>("");
    //
    const [units, setUnits] = useState<UnitRes[]>([]);
    //
    const [modalMessage, setModalMessage] = useState("");
    const [showModal, setshowModal] = useState(false);

    //
    useEffect(() => {
        async function process() {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + TOKEN.val,
                    "Content-Type": "application/json"
                }
            }
            const res = await fetch(BASE_URL + "units", requestOptions);
            const results = await res.json();
            setUnits(results);
        }
        process();
    }, []);

    const handleClickSave = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let unit: UnitReq = {
            name: name,
            code: code,
            isDerived: isDerived,
            baseUnitId: null,
            operatorId: null,
            qty: null,
        }

        if (isDerived) {
            unit.baseUnitId = baseUnitId;
            unit.operatorId = operatorId;
            unit.qty = parseFloat(qty);
        }

        const requestOptions = {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + TOKEN.val,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(unit)
        }
        const res = await fetch(BASE_URL + "units", requestOptions);
        if (res.status !== 201) {
            const data = await res.json();
            setModalMessage(data.message);
            setshowModal(true);
        }
        else {
            setModalMessage("Saved");
            setshowModal(true);
            clearBoxes();
        }
    }

    const handleClose = () => setshowModal(false);
    const handleShow = () => setshowModal(true);

    const clearBoxes = () => {

    };

    return (
        <>
            <div className="container-fluid">
                <form onSubmit={(event) => handleClickSave(event)}>
                    <div className="row col-sm-6">
                        <div className="row align-items-center mb-3">
                            <div className="col-sm-2">
                                <label className="col-form-label">
                                    Code
                                </label>
                            </div>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row align-items-center mb-3">
                            <div className="col-sm-2">
                                <label className="col-form-label">
                                    Name
                                </label>
                            </div>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row align-items-center mb-3">
                            <div className="col-sm-10 offset-sm-2">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={isDerived}
                                        id="flexCheckDefault"
                                        onChange={(e) => setIsDerived(e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Is Derived unit
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center mb-3">
                            <div className="col-sm-2">
                                <label className="col-form-label">
                                    Unit
                                </label>
                            </div>
                            <div className="col-sm-10">
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    value={baseUnitId}
                                    onChange={(e) => setBaseUnitId(parseInt(e.target.value))}>
                                    <option value="">[SELECT]</option>
                                    {units.map((unit, index) => (
                                        <option key={index} value={unit.id}>{unit.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row align-items-center mb-3">
                            <div className="col-sm-2">
                                <label className="col-form-label">
                                    Operator
                                </label>
                            </div>
                            <div className="col-sm-10">
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    value={operatorId}
                                    onChange={(e) => setOperatorId(parseInt(e.target.value))}>
                                    <option value="-1">[SELECT]</option>
                                    <option value="1" >Divide ( / )</option>
                                    <option value="2" >Multiply ( X )</option>
                                </select>
                            </div>
                        </div>
                        <div className="row align-items-center mb-3">
                            <div className="col-sm-2">
                                <label className="col-form-label">
                                    Quantity
                                </label>
                            </div>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={qty.toString()}
                                    onChange={(e) => setQty(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            </div>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default AddUnit;
