export default class ListEstimateSm {

	no: number | undefined;
	dateFrom: Date | undefined;
	dateTo: Date | undefined;
	customerId: number | undefined;
	customerName: string | undefined;
	convertedToSo: boolean | undefined;
	city: string | undefined;
	stateId: number | undefined;
	state: string | undefined;
	agentId: number | undefined;
	agentName: string | undefined;
	note: string | undefined;
	statusId: number | undefined;

	reset(): void {

		this.no = undefined;
		this.dateFrom = undefined;
		this.dateTo = undefined;
		this.customerId = undefined;
		this.customerName = undefined;
		this.convertedToSo = undefined;
		this.city = undefined;
		this.stateId = undefined;
		this.state = undefined;
		this.agentId = undefined;
		this.agentName = undefined;
		this.note = undefined;
		this.statusId = undefined;
	}

	isBlank(): boolean {
		return this.no === undefined
			&& this.dateFrom === undefined
			&& this.dateTo === undefined
			&& this.customerId === undefined
			&& this.customerName === undefined
			&& this.convertedToSo === undefined
			&& this.city === undefined
			&& this.stateId === undefined
			&& this.state === undefined
			&& this.agentId === undefined
			&& this.agentName === undefined
			&& this.note === undefined
			&& this.statusId === undefined;
	}
}
