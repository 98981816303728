import React, { forwardRef, HTMLProps } from "react";
import styles from "./styles.module.css";


interface OptionsListProps extends HTMLProps<HTMLElement> {
	isVirtualized: boolean;
}

export const OptionsList = forwardRef<
	HTMLElement | HTMLUListElement | null,
	OptionsListProps
>((props, ref) => {
	const { isVirtualized, ...rest } = props;
	const classNames = [styles["options-list"]];
	if (!isVirtualized) classNames.push(styles["max-height-200"]);

	if (isVirtualized) {
		return <div className={classNames.join(" ")} {...rest} ref={ref as React.RefObject<HTMLDivElement>} />;
	} else {
		return <ul className={classNames.join(" ")} {...rest} ref={ref as React.RefObject<HTMLUListElement>} />;
	}
});


interface OptionProps extends HTMLProps<HTMLLIElement> {
	children: string;
	index: number;
	highlightedIndex: number | null;
	item: string;
	selectedItem: string;
	inputValue: string;
	isVirtualized?: boolean;
	itemWidth?: string | undefined;
}

export const Option = forwardRef<HTMLLIElement, OptionProps>(
	(
		{
			children,
			index,
			highlightedIndex,
			item,
			selectedItem,
			inputValue,
			isVirtualized,
			itemWidth,
			style,
			...rest
		},
		ref
	) => {
		const isActive = highlightedIndex === index;
		const isSelected = selectedItem === item;
		const classNames = [styles["option"]];

		if (isSelected) classNames.push(styles["is-selected"]);
		if (isActive) classNames.push(styles["is-active"]);
		if (isVirtualized) classNames.push(styles["is-virtualized"]);
		const newStyle = {
			...style,
			width: itemWidth || styles.width,
		};


		// Call the function component and return its result within JSX
		const boldMatchText = () => {
			const lowerText = children.toLowerCase();
			const matchIndex = lowerText.indexOf(inputValue.toLowerCase());

			if (matchIndex < 0) return <span>{children}</span>;

			const beginning = children.substr(0, matchIndex);
			const match = children.substr(matchIndex, inputValue.length);
			const end = children.substr(matchIndex + match.length);
			return (
				<>
					{beginning}
					<span className={styles["bold-option-text"]}>{match}</span>
					{end}
				</>
			);
		};

		return (
			<li className={classNames.join(" ")} {...rest} style={newStyle} ref={ref}>
				{boldMatchText()}
			</li>
		);
	}
);
